import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import Paper from "@mui/material/Paper";
import React, { useState, useEffect, useReducer } from "react";
import { PopulatedStore, getAllStores } from "../api/store";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Region, getAllRegions } from "../api/region";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import useTheme from "@mui/material/styles/useTheme";
import { Routes } from "react-router-dom";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { RouteVideo, getRouteVideo } from "../api/routevideo";
import { grey } from "@mui/material/colors";
import ReactPlayer from "react-player";
import Stack from "@mui/material/Stack";

interface Step {
  label: string;
  description: JSX.Element;
  startingRegion: Region;
  endingRegion: Region;
  crossLevel: boolean;
}
const RenderRouteChips = ({ route }: { route: any }) => {
  const pairs = [];
  for (let index = 0; index < route.length - 1; index++) {
    pairs.push(
      <Tooltip
        title={
          <>
            <Typography>{`${route[index].level.name} - ${route[index].name}`}</Typography>
            <Typography>{`${route[index + 1].level.name} - ${
              route[index + 1].name
            }`}</Typography>
          </>
        }
        key={`${route[index]._id}-${route[index + 1]._id}-route-tooltip`}
        arrow
      >
        <Chip
          key={`${route[index]._id}-${route[index + 1]._id}-route-label`}
          label={[route[index].name, route[index + 1].name].join(">")}
        />
      </Tooltip>
    );
  }
  return <>{pairs}</>;
};

const TextMobileStepper = ({ route }: { route: any }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [maxSteps, setMaxSteps] = useState(route.length - 1);
  const [steps, setSteps] = useState<Array<Step>>([]);

  const normalStep = (
    startingRegion: Region,
    endingRegion: Region,
    routeVideo: RouteVideo,
    requireTransition: boolean
  ): Step => {
    return {
      label: `${startingRegion.name} > ${endingRegion.name}`,
      startingRegion,
      endingRegion,
      crossLevel: false,
      description: (
        <Stack
          key={`${startingRegion._id}-elevator-to-${endingRegion._id}`}
          alignItems="center"
        >
          {routeVideo.videoURL ? (
            <ReactPlayer
              width={"auto"}
              muted={true}
              playing={true}
              controls={true}
              config={{
                youtube: {
                  playerVars: {
                    start: requireTransition
                      ? routeVideo.transitionTimingInSeconds
                      : 0,
                  },
                },
              }}
              url={routeVideo.videoURL}
            />
          ) : (
            <Box
              sx={{
                p: 2,
                m: 2,
                top: "80%",
                width: "80%",
                borderRadius: "2px",
                backgroundColor: "#b71c1c",
              }}
            >
              No video found for this step
            </Box>
          )}
          {/* Youtube video URL {routeVideo?.videoURL} and startsSeconds{" "}
          {routeVideo?.transitionTimingInSeconds} */}
        </Stack>
      ),
    };
  };
  const crossLevelStep = (
    startingRegion: Region,
    endingRegion: Region
  ): Step => {
    return {
      label: `${startingRegion.level.name} > ${endingRegion.level.name}`,
      // label: `${startingRegion.name} > ${endingRegion.name}`,
      startingRegion,
      endingRegion,
      crossLevel: true,
      description: (
        <div key={`${startingRegion._id}-elevator-to-${endingRegion._id}`}>
          Please take a elevator/escalator to {endingRegion.level.name}
        </div>
      ),
    };
  };

  function recursiveProcessArray(route: any) {
    const tempSteps: Array<Step> = [];

    const processNext = (index: number): any => {
      if (index >= route.length - 1) {
        // All API calls completed, return the tempSteps array
        return tempSteps;
      }
      //Sequentially calls api starting from first index
      return getRouteVideo(route[index], route[index + 1])
        .then((routeVideo: any) => {
          if (routeVideo) {
            if (routeVideo.crossLevel) {
              tempSteps.push(crossLevelStep(route[index], route[index + 1]));
            } else {
              const prevStep = tempSteps.at(-1);
              if (prevStep === undefined) {
                //First step show full video
                tempSteps.push(
                  normalStep(route[index], route[index + 1], routeVideo, false)
                );
              } else if (prevStep.crossLevel === false) {
                //Previous step was a normal step thus show part of the video
                tempSteps.push(
                  normalStep(route[index], route[index + 1], routeVideo, true)
                );
              } else if (prevStep.crossLevel === true) {
                //Prev step was cross level step thus show full video
                tempSteps.push(
                  normalStep(route[index], route[index + 1], routeVideo, false)
                );
              }
            }
          }
          return processNext(index + 1);
        })
        .catch((error) => {
          console.error(`Error: `, error);
          return processNext(index + 1);
        });
    };

    return processNext(0);
  }
  useEffect(() => {
    setIsLoading(true);
    setMaxSteps(route.length - 1);
    setActiveStep(0);
    recursiveProcessArray(route).then((steps: Array<Step>) => {
      setSteps(steps);
    });
  }, [route]);
  useEffect(() => {
    if (steps.length === route.length - 1 && isLoading) {
      //Go through one final loop through steps and join all the cross levels
      //By this point the steps should all be in order
      let compressedSteps: Array<Step> = [];
      for (let index = 0; index < route.length - 1; index++) {
        const currentStep = _.cloneDeep(steps[index]);
        const prevCompressedStep = _.cloneDeep(compressedSteps.at(-1));
        if (
          currentStep.startingRegion.level._id ===
          currentStep.endingRegion.level._id
        ) {
          //Traversal within same level cannot be compressed
          compressedSteps.push(currentStep);
        } else if (
          currentStep.startingRegion.level._id !==
          currentStep.endingRegion.level._id
        ) {
          //The current step is crosslevel
          if (prevCompressedStep === undefined) {
            //First step is a cross level
            compressedSteps.push(currentStep);
          } else if (
            prevCompressedStep.startingRegion.level._id !==
            prevCompressedStep.endingRegion.level._id
          ) {
            //Two consecutive crosslevel, can be compressed
            //Compression will cause maxsteps to decrease by 1
            setMaxSteps((prevMaxSteps) => prevMaxSteps - 1);
            compressedSteps = [
              ...compressedSteps.slice(0, -1),
              crossLevelStep(
                prevCompressedStep.startingRegion,
                currentStep.endingRegion
              ),
            ];
          } else if (
            prevCompressedStep.startingRegion.level._id ===
            prevCompressedStep.endingRegion.level._id
          ) {
            //previous step is not a cross level but current state is a cross level
            compressedSteps.push(currentStep);
          }
        }
      }
      // const routeCopy = _.cloneDeep(route).map(
      //   (region: Region, index: number) => {
      //     if (index < route.length - 1)
      //       return `${region.name} > ${_.cloneDeep(route[index + 1].name)}`;
      //   }
      // );
      // routeCopy.slice(0, -1).map((potentialRoute: string, index: number) => {
      //   console.log(potentialRoute);
      //   console.log(steps[index].label);
      //   potentialRoute !== steps[index].label
      //     ? console.log("A difference was spotted at index ", index)
      //     : null;
      // });
      setSteps(compressedSteps);
      setIsLoading(false);
    }
  }, [steps]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Box sx={{ flexGrow: 1, py: 2, px: 1 }}>
      {isLoading && (
        <CircularProgress value={Math.floor(steps.length / maxSteps)} />
      )}
      {!isLoading && (
        <>
          <Paper
            square
            elevation={0}
            sx={{
              alignItems: "center",
              px: 1,
              paddingTop: 2,
              bgcolor: "background.default",
            }}
          >
            <Typography>{steps[activeStep].label}</Typography>
            <Box sx={{ minHeight: 255, p: 2, background: grey["A700"] }}>
              {activeStep === maxSteps - 1 && (
                <Typography sx={{ fontWeight: "bold" }}>
                  {"Store is near, look around"}
                </Typography>
              )}
              {steps[activeStep].description}
            </Box>
            <MobileStepper
              variant="text"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </Paper>
        </>
      )}
    </Box>
  );
};
export default function ShopFinder() {
  const [isLoading, setIsLoading] = useState(true);
  const [stores, setStores] = useState<Array<PopulatedStore>>([]);
  const [regions, setRegions] = useState<Array<Region>>([]);
  const [startingLocation, setStartingLocation] = useState<PopulatedStore>();
  const [endingLocation, setEndingLocation] = useState<PopulatedStore>();
  const [route, setRoute] = useState<any>([]);
  const [couldNotFindRoute, setCouldNotFindRoute] = useState("");
  const [isGeneratingRoute, setIsGeneratingRoute] = useState(false);
  useEffect(() => {
    getAllStores().then((data) => {
      //Sort stores by their level id
      setStores(_.orderBy(data, ["level._id", "unit"], ["asc", "asc"]));
    });
    getAllRegions().then((data) => {
      setRegions(data);
    });
    setIsLoading(false);
  }, []);
  useEffect(() => {
    if (startingLocation != null && endingLocation != null) {
      if (startingLocation.region._id === endingLocation.region._id) {
        setCouldNotFindRoute("Store is in the same region");
        setRoute([]);
      } else {
        setIsGeneratingRoute(true);
        const startingRegion = startingLocation.region;
        const endingRegion = endingLocation.region;
        let prevRoutes: Array<Array<string>> = [[startingRegion._id]];
        let currentRoutes: Array<Array<string>> = [];
        let foundRoute = false;
        /*
        Steps to solve
        1) Find the region the starting and ending locations are
        2) Conduct Breath First Search using the regions and their connections
        3) End when starting and ending regions are correct
        Points to note
        */
        while (!foundRoute) {
          //Iterate through prevRoute
          prevRoutes.map((prevRoute) => {
            const connectedToRegions = regions.find(
              (region) => region._id === prevRoute[prevRoute.length - 1]
            )?.connectedTo; //Find the current prevRoute connectedTo Regions
            if (connectedToRegions == null) return;
            connectedToRegions.map((connectedRegion) => {
              //Add new connections to the prevRoute being iterated
              //Do not add the route if the regionID is already in the current route
              if (!prevRoute.includes(connectedRegion)) {
                currentRoutes.push([...prevRoute, connectedRegion]);
                if (
                  currentRoutes[currentRoutes.length - 1][
                    currentRoutes[currentRoutes.length - 1].length - 1
                  ] === endingRegion._id
                ) {
                  //The current route is checked if it has reached the final/ending location
                  if (currentRoutes == null) return;
                  //If current route has reached the ending, set the route and populate it with all the region details required
                  setRoute(
                    currentRoutes[currentRoutes.length - 1].map(
                      (currentRegionID) => {
                        const region = regions.find(
                          (region) => region._id === currentRegionID
                        );
                        if (region == null) return;
                        return region;
                      }
                    )
                  );
                  //ends the while loop
                  foundRoute = true;
                }
              }
            });
          });
          //Replace the prev route with the route that were just generated
          prevRoutes = [...currentRoutes];
          if (prevRoutes.length === 0) {
            //This occurs if no valid route can be found
            foundRoute = true;
            setRoute([]);
            setCouldNotFindRoute("Unable to find a route");
          }
          //Empty current Routes for the next iteration
          currentRoutes = [];
        }
        //After route is found set flag to false
        setIsGeneratingRoute(false);
      }
    } else {
      setCouldNotFindRoute("");
    }
  }, [startingLocation, endingLocation]);
  return (
    <>
      <Paper
        sx={{
          py: 2,
          my: 4,
          borderRadius: 4,
          maxWidth: 0.95,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {!isLoading && (
          <Box component="div">
            <Autocomplete
              disableClearable
              sx={{ m: 2 }}
              id="starting-location"
              getOptionLabel={(option) =>
                `${option.level.name} - ${option.unit}`
              }
              groupBy={(option) => option.level.name}
              options={stores}
              isOptionEqualToValue={(option, value) => {
                if (option._id === value._id) {
                  return true;
                } else {
                  return false;
                }
              }}
              onChange={(event, selectedStore) => {
                if (selectedStore != null) setStartingLocation(selectedStore);
                else setEndingLocation(undefined);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Starting Location" />
              )}
            />
            <Autocomplete
              disableClearable
              sx={{ m: 2 }}
              id="ending-location"
              getOptionLabel={(option) =>
                `${option.level.name} - ${option.unit}`
              }
              groupBy={(option) => option.level.name}
              options={stores}
              isOptionEqualToValue={(option, value) => {
                if (option._id === value._id) {
                  return true;
                } else {
                  return false;
                }
              }}
              onChange={(event, selectedStore) => {
                if (selectedStore != null) setEndingLocation(selectedStore);
                else setEndingLocation(undefined);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Ending Location" />
              )}
            />
          </Box>
        )}
      </Paper>
      <Paper
        sx={{
          py: 1,
          my: 2,
          px: 1,
          borderRadius: 4,
          maxWidth: 0.95,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Box sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography component="h5" sx={{ m: 2 }}>
            Route{" "}
          </Typography>
          {isGeneratingRoute && <CircularProgress />}
          {!isGeneratingRoute &&
            route.length !== 0 &&
            process.env.NODE_ENV === "development" && (
              <RenderRouteChips route={route} />
            )}
          {!isGeneratingRoute && route.length !== 0 && (
            <TextMobileStepper route={route} />
          )}
          {route.length === 0 &&
            endingLocation &&
            startingLocation &&
            couldNotFindRoute}
        </Box>
      </Paper>
    </>
  );
}
