let backendURL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:5000/api"
    : `${window.location.origin}/api`;
const genericResponseHandler = async (response: Response) => {
  if (response.status === 500) {
    throw new Error("Internal Server Error");
  } else if (response.status === 200) {
    return await response.json();
  }
};
/*
In production API can access a json file instead of calling a backend api as there's no need for users to access the api
*/
interface successfulPOSTResponse {
  message: string;
}
interface successfulPUTResponse {
  message: string;
}
interface successfulDELETEResponse {
  message: string;
}
export type {
  successfulPOSTResponse,
  successfulPUTResponse,
  successfulDELETEResponse,
};
export { backendURL, genericResponseHandler };
