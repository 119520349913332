import { backendURL, genericResponseHandler } from ".";
import { Region } from "./region";

import { routevideosData } from "./data";
interface RouteVideo {
  startingRegion: string;
  endingRegion: string;
  crossLevel?: boolean;
  videoURL?: string;
  transitionTimingInSeconds?: number;
}
const routeVideos: Array<RouteVideo> = routevideosData.map((routeVideo) => {
  return {
    _id: routeVideo._id.$oid,
    startingRegion: routeVideo.startingRegion.$oid,
    endingRegion: routeVideo.endingRegion.$oid,
    crossLevel: routeVideo.crossLevel,
    transitionTimingInSeconds: routeVideo.transitionTimingInSeconds,
    videoURL: routeVideo.videoURL,
  };
});
export type { RouteVideo };
export async function getRouteVideo(
  startingRegion: Region,
  endingRegion: Region
): Promise<RouteVideo | undefined> {
  if (process.env.REACT_APP_ONLY_GET === "enabled") {
    return routeVideos.find(
      (routeVideo) =>
        routeVideo.startingRegion === startingRegion._id &&
        routeVideo.endingRegion === endingRegion._id
    );
  } else {
    const response = await fetch(
      `${backendURL}/routevideo?startingRegionID=${startingRegion._id}&endingRegionID=${endingRegion._id}`
    );
    if (response.ok) {
      const data: RouteVideo = await response.json();
      if ("message" in data && data.message === "RouteVideo not found") {
        return await createNewRouteVideo(startingRegion, endingRegion);
      }
      return data;
    }
  }
}
export async function createNewRouteVideo(
  startingRegion: Region,
  endingRegion: Region,
  videoURL?: string,
  transitionTimingInSeconds?: number
) {
  return await genericResponseHandler(
    await fetch(`${backendURL}/routevideo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startingRegion,
        endingRegion,
        videoURL,
        transitionTimingInSeconds,
      }),
    })
  );
}

export async function updateRouteVideo(
  startingRegion: string,
  endingRegion: string,
  videoURL?: string,
  transitionTimingInSeconds?: number
) {
  return await genericResponseHandler(
    await fetch(`${backendURL}/routevideo`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startingRegion,
        endingRegion,
        videoURL,
        transitionTimingInSeconds,
      }),
    })
  );
}
