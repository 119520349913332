import { backendURL } from ".";
import { levelsData } from "./data";
const levels: Array<Level> = levelsData.map((level) => {
  return { _id: level._id.$oid, name: level.name };
});
interface Level {
  _id: string;
  name: string;
}
export type { Level };
export async function getAllLevels() {
  const response = await fetch(`${backendURL}/level`, {
    method: "GET",
  });
  const jsonData = await response.json();
  return jsonData;
}
