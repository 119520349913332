import React, { useState } from "react";
import SimpleAppBar from "./Components/SimpleAppBar";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
console.log(process.env.REACT_APP_ONLY_GET);
if (
  process.env.REACT_APP_ONLY_GET === "enabled" &&
  process.env.NODE_ENV === "development"
) {
  alert("Only GET enabled");
}

function App() {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) => theme.palette.grey[600],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <SimpleAppBar />
      <Outlet />
    </Box>
  );
}

export default App;
