import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LabelImage from "./Pages/LabelImage";
import ShopFinder from "./Pages/ShopFinder";

const pages =
  process.env.NODE_ENV === "production"
    ? []
    : ["labelregions", "stores", "finder"];
export { pages };
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const mdTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const router =
  process.env.NODE_ENV === "production"
    ? createBrowserRouter([
        {
          path: "/",
          element: <App />,
          children: [
            {
              path: "/",
              element: <ShopFinder />,
            },
            {
              path: "*",
              element: <div>Site is missing</div>,
            },
          ],
        },
      ])
    : createBrowserRouter([
        {
          path: "/",
          element: <App />,
          children: [
            {
              path: "/",
              element: <div>Welcome to the dev site</div>,
            },
            {
              path: pages[0],
              element: <LabelImage />,
            },
            {
              path: pages[2],
              element: <ShopFinder />,
            },
            {
              path: "*",
              element: <div>Site is missing</div>,
            },
          ],
        },
      ]);
root.render(
  <ThemeProvider theme={mdTheme}>
    <RouterProvider router={router} />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
