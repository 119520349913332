import {
  backendURL,
  genericResponseHandler,
  successfulDELETEResponse,
  successfulPOSTResponse,
  successfulPUTResponse,
} from ".";
import { levelsData, regionsData } from "./data";
const regions: Array<Region> = regionsData.map((region) => {
  return {
    _id: region._id["$oid"],
    name: region.name,
    level: {
      _id:
        levelsData.find((level) => level._id.$oid === region.level.$oid)?._id
          .$oid ?? "randomid",
      name:
        levelsData.find((level) => level._id.$oid === region.level.$oid)
          ?.name ?? "randomstuff",
    },
    stores: region.stores.map((store) => store.$oid),
    connectedTo: region.connectedTo.map(
      (connectedRegions) => connectedRegions.$oid
    ),
  };
});
interface Region {
  _id: string;
  name: string;
  level: {
    _id: string;
    name: string;
  };
  stores: Array<string>;
  connectedTo: Array<string>;
}
export type { Region };
export async function addNewRegion(
  regionName: string,
  levelOID: string
): Promise<successfulPOSTResponse> {
  return await genericResponseHandler(
    await fetch(`${backendURL}/region`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        regionName,
        levelOID,
      }),
    })
  );
}
export async function connectToRegion(
  currentRegionOID: string,
  connectRegionOID: string
): Promise<successfulPUTResponse> {
  return await genericResponseHandler(
    await fetch(`${backendURL}/region/connect`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        currentRegionOID,
        connectRegionOID,
      }),
    })
  );
}
export async function disconnectWithRegion(
  currentRegionOID: string,
  connectRegionOID: string
): Promise<successfulPUTResponse> {
  return await genericResponseHandler(
    await fetch(`${backendURL}/region/disconnect`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        currentRegionOID,
        connectRegionOID,
      }),
    })
  );
}
export async function deleteRegion(
  currentRegionOID: string
): Promise<successfulDELETEResponse> {
  return await genericResponseHandler(
    await fetch(`${backendURL}/region`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        removeRegionOID: currentRegionOID,
      }),
    })
  );
}
export async function getAllRegionsInLevel(
  levelOID: string
): Promise<Array<Region>> {
  return await genericResponseHandler(
    await fetch(`${backendURL}/region/${levelOID}`)
  );
}
export async function getAllRegions(): Promise<Array<Region>> {
  if (process.env.REACT_APP_ONLY_GET === "enabled") {
    return regions;
  } else {
    return await genericResponseHandler(await fetch(`${backendURL}/region`));
  }
}
